import React from 'react'
import './Card.css'
import { Link } from "gatsby"

const Card = props => (
       

        <Link to={props.link} className='Card'>
            <p>{props.title}</p>
            <img src={props.image} alt={props.alt}/>
        </Link>

);



export default Card